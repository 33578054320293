import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import SearchBar from "@Atom/SearchBar";
import Button from "@Atom/Button";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Modal from "@Atom/Modal";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { fileBaseUrl } from "configs";
import { useDebounce } from "@Hooks/useDebounce";
import { updateSequencePatrolPoint } from "@Services/patrolPoint/updateSequence";
import Toast from "@Atom/Toast";
import { addPatrolPoint } from "@Services/patrolPoint/AddPatrollPoint";
import PDFBarcodeDownload from "@Molecule/PDFBarcode";
import useWindowSize from "@Hooks/useWindowSize";
import CustomEmptyHandlerPage from "@Atom/CustomEmptyHandlerPage";
import Images from "@Theme/Images";
import LoadingSpinner from "@Molecule/LoadingSpinner";

import ModalAddPatrolPoint from "@Molecule/_modal/AddPatrolPoint";
import { capitalizeEachWord } from "helpers/capitalizeEachWord";
import Spinner from "@Atom/Spinner";
import { getAllPatrolPoint } from "@Services/patrolPoint/getAllPatrollPoint";
import Skeleton, { BoxSkeleton } from "@Atom/Skeleton";

const ItemType = {
  CARD: "card",
};

// Komponen untuk setiap Card di dalam modal
const SortableCard = ({ card, index, moveCard, isUnsetBorder = false }) => {
  const ref = useRef(null);

  const [, drop] = useDrop({
    accept: ItemType.CARD,
    hover(item) {
      if (item.index !== index) {
        moveCard(item.index, index);
        item.index = index;
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType.CARD,
    item: { id: card._id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div
      className={Styles.listCard}
      ref={ref}
      style={{
        backgroundColor: isDragging ? "#f0f0f0" : "#ffffff",
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      <Icon
        icon={"burger-menu"}
        className={Styles.burgerMenuIcon}
        size={"24"}
      />
      <div
        className={`${Styles.wrapDivider} ${
          isUnsetBorder ? Styles.unsetDivider : ""
        } `}
      >
        <div className={Styles.wrapImage}>
          <img src={fileBaseUrl + card.imageURI} alt="image-location-patrol" />
        </div>
        <div className={Styles.nameLocation}>
          <strong>{capitalizeEachWord(card.name)} </strong>
          <span>{capitalizeEachWord(card.description)}</span>
        </div>
      </div>
    </div>
  );
};

export default function ListPatrolPoint({ isLoader = true }) {
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const { pathname, search: searchParamsPath } = useLocation();
  const { id: locationID } = useParams();
  const [isOpenAddPoint, setIsOpenAddPoint] = useState(false);
  const [isOpenSortPatrol, setIsOpenSortPatrol] = useState(false);
  const [cardOrder, setCardOrder] = useState([]);
  const [isLoadingScroll, setIsLoadingScroll] = useState(false);
  const [totalPage, setTotalPage] = useState(1);

  const [data, setData] = useState({ data: [] });
  const [isInitialLoader, setIsInitialLoader] = useState(isLoader);
  const [isTransition, setTransition] = useState(false);
  const [isLoadingHit, setIsLoadingHit] = useState(false);
  const limit = 10;
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const debounceQuery = useDebounce(searchQuery, 500);

  useEffect(() => {
    setPage(1);
  }, [searchQuery]);

  useEffect(() => {
    if (isOpenSortPatrol && !isTransition) {
      setTimeout(() => {
        setTransition(true);
      }, 100);
    }
  }, [isOpenSortPatrol, isTransition]);

  const fetchMoreData = useCallback(async () => {
    if (isLoadingScroll || page > totalPage) return;
    setIsLoadingScroll(true);

    try {
      if (debounceQuery && page === 1) {
        setData({ data: [] });
      }

      const res = await getAllPatrolPoint(
        page,
        limit,
        locationID,
        debounceQuery
      );

      if (debounceQuery) {
        setData({ data: res?.data });
        setTotalPage(res?.totalPage || 1);
        return;
      }

      const newData = res?.data || [];
      const updatedData = [
        ...data.data,
        ...newData.filter(
          (item) =>
            !data.data.some((existingItem) => existingItem._id === item._id)
        ),
      ];

      setData({ data: updatedData });
      setTotalPage(res?.totalPage || 1);
    } catch (error) {
      console.error("Error fetching list patrol:", error);
    } finally {
      setIsLoadingScroll(false);
      setIsInitialLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, debounceQuery, locationID]);

  useEffect(() => {
    fetchMoreData();
  }, [fetchMoreData]);

  const moveCard = (fromIndex, toIndex) => {
    if (fromIndex === toIndex) return;

    setCardOrder((prevCardOrder) => {
      const updatedCardOrder = [...prevCardOrder];
      const [movedCard] = updatedCardOrder.splice(fromIndex, 1);
      updatedCardOrder.splice(toIndex, 0, movedCard);
      return updatedCardOrder;
    });
  };

  useEffect(() => {
    if (data?.data) setCardOrder(data.data);
  }, [data?.data]);

  const handleBack = () => {
    navigate("/work-location");
    sessionStorage.setItem("activeSubMenu", 0);
  };

  const [isSuccessChangeSequence, setIsSuccessChangeSequence] = useState(false);
  const [isErrorChangeSequence, setIsErrorChangeSequence] = useState(false);

  // update sequence
  const handleSortPatrolPoint = async () => {
    setIsLoadingHit(true);
    const dataToSend = new URLSearchParams();
    const dataCurrent = data?.data;
    cardOrder.forEach((card, idx) => {
      if (idx === 0) {
        dataToSend.append(`locationID`, locationID);
      }
      dataToSend.append(`checkpoints[${idx}][checkpointID]`, card?._id);
      dataToSend.append(`checkpoints[${idx}][currentNum]`, card?.num);
      dataToSend.append(`checkpoints[${idx}][newNum]`, dataCurrent[idx]?.num);
    });

    try {
      const res = await updateSequencePatrolPoint(dataToSend);
      if (res.status === 200) {
        setIsSuccessChangeSequence(true);
        navigate(`${pathname}${searchParamsPath}`, { replace: true });
        setIsOpenSortPatrol(false);
      }
    } catch (error) {
      console.log("error sort change patrol point: ", error);
      setIsErrorChangeSequence(true);
    } finally {
      setIsLoadingHit(false);
    }
  };

  // create
  const [isSuccessCreatePatrolPoint, setIsSuccessCreatePatrolPoint] =
    useState(false);
  const [isErrorCreatePatrolPoint, setIsErrorCreatePatrolPoint] =
    useState(false);

  const [selectedLocation, setSelectedLocation] = useState({
    latitude: 0,
    longitude: 0,
  });
  const [inputPatrolPoint, setInputPatrolPoint] = useState({
    name: "",
    image: "",
  });

  const fileRef = useRef(null);

  const handleAddPatrolPoint = async () => {
    setIsLoadingHit(true);
    setIsOpenAddPoint(false);
    const formData = new FormData();
    formData.append("locationID", locationID);
    formData.append("latitude", selectedLocation.latitude);
    formData.append("longitude", selectedLocation.longitude);
    formData.append("description", inputPatrolPoint.name);
    formData.append("file", inputPatrolPoint.image);

    try {
      const res = await addPatrolPoint(formData);
      if (res.status === 201) {
        setSelectedLocation({
          latitude: 0,
          longitude: 0,
        });
        setInputPatrolPoint({
          name: "",
          image: "",
        });
        fetchMoreData();
        setIsSuccessCreatePatrolPoint(true);
      }
    } catch (error) {
      console.log("error add patrol point", error);
      setIsErrorCreatePatrolPoint(true);
    } finally {
      setIsLoadingHit(false);
    }
  };

  const locationName = sessionStorage.getItem("nameDetailInfoWorkLocation");

  const listInnerRefMainContain = useRef();
  // const listInnerRefSortContain = useRef();

  const onScroll = useCallback(() => {
    if (listInnerRefMainContain.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        listInnerRefMainContain.current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        if (page < totalPage) {
          setPage((p) => p + 1);
        }
      }
    }
  }, [page, totalPage]);

  // const onScrollSort = useCallback(() => {
  //   if (listInnerRefSortContain.current) {
  //     const { scrollTop, scrollHeight, clientHeight } =
  //       listInnerRefSortContain.current;
  //     if (scrollTop + clientHeight >= scrollHeight - 5) {
  //       if (page < totalPage) {
  //         setPage((p) => p + 1);
  //       }
  //     }
  //   }
  // }, [page, totalPage]);

  // useEffect(() => {
  //   if (height >= 768) {
  //     onScroll();
  //     if (isOpenSortPatrol) {
  //       onScrollSort();
  //     }
  //   }
  // }, [height, onScroll, onScrollSort, isOpenSortPatrol]);

  const [isTransitionModalAdd, setIsTransitionModalAdd] = useState(false);

  useEffect(() => {
    if (isOpenAddPoint) {
      setIsTransitionModalAdd(true);
    } else {
      setIsTransitionModalAdd(false);
    }
  }, [isOpenAddPoint]);

  useEffect(() => {
    if (isInitialLoader) {
      const preventClick = (event) => {
        const pageContainer = document.getElementById("list-patrol-point-page");
        if (pageContainer && pageContainer.contains(event.target)) {
          event.preventDefault();
          event.stopImmediatePropagation();
        }
      };
      document.addEventListener("click", preventClick, true);

      return () => {
        document.removeEventListener("click", preventClick, true);
      };
    }
  }, [isInitialLoader]);

  return (
    <>
      <Toast
        isopen={isSuccessChangeSequence || isSuccessCreatePatrolPoint}
        color="green"
        onClose={() => {
          setIsSuccessChangeSequence(false);
          setIsSuccessCreatePatrolPoint(false);
        }}
        text={
          isSuccessChangeSequence
            ? "Berhasil memperbarui urutan"
            : "Berhasil menambahkan lokasi "
        }
      />

      <Toast
        isopen={isErrorChangeSequence || isErrorCreatePatrolPoint}
        color="red"
        onClose={() => {
          setIsErrorChangeSequence(false);
          setIsErrorCreatePatrolPoint(false);
        }}
        text={
          isErrorChangeSequence
            ? "Gagal memperbarui urutan"
            : "Gagal menambahkan lokasi"
        }
      />

      {isLoadingHit && <LoadingSpinner text="Sedang menyimpan..." />}

      {isOpenAddPoint && (
        <ModalAddPatrolPoint
          isOpen={isOpenAddPoint}
          onClose={() => setIsOpenAddPoint(false)}
          fileRef={fileRef}
          onSubmit={() => handleAddPatrolPoint()}
          inputLocation={inputPatrolPoint}
          setInputLocation={setInputPatrolPoint}
          setSelectedLocation={setSelectedLocation}
          selectedLocation={selectedLocation}
          isFilled
          isTransition={isTransitionModalAdd}
        />
      )}

      <div
        className={Styles.wrapperListPatrolPoint}
        id="list-patrol-point-page"
      >
        <div
          className={`${Styles.mainContainListPatrolPoint} ${
            cardOrder.length === 0 && Styles.isEmpty
          } `}
        >
          <div className={Styles.between}>
            <div className={Styles.back}>
              <Icon
                icon={"arrow-chevron-left"}
                size={"20"}
                className={Styles.backIcon}
                onClick={() => handleBack()}
              />
              <span>{capitalizeEachWord(locationName)}</span>
            </div>
            {/* <Button
              text="Tambah Titik Patroli"
              isAddButton
              isLeftIcon
              onClick={() => setIsOpenAddPoint(true)}
            /> */}
          </div>

          {cardOrder.length === 0 && !debounceQuery && !isInitialLoader ? (
            <CustomEmptyHandlerPage
              title="Lokasi Titik Patroli Belum Ditambahkan"
              description="Silahkan lakukan penambahan lokasi titik patroli"
              onClick={() => setIsOpenAddPoint(true)}
              images={Images.POINT_NAVIGATION}
              btnText="Tambah Titik Lokasi Patroli"
            />
          ) : (
            <div className={Styles.viewFilter}>
              <SearchBar setValue={setSearchQuery} value={searchQuery} />
              <div style={{ display: "flex", gap: "12px" }}>
                <Button
                  text="Atur Urutan Patroli"
                  icon={"arrow-swap"}
                  colorIcon={"#3E4856"}
                  style={{ background: "transparent", color: "#3E4856" }}
                  onClick={() => setIsOpenSortPatrol(!isOpenSortPatrol)}
                />
                <Button
                  text="Tambah Titik Patroli"
                  isAddButton
                  isLeftIcon
                  onClick={() => setIsOpenAddPoint(true)}
                />
              </div>

              {isOpenSortPatrol && (
                <DndProvider backend={HTML5Backend}>
                  <Modal isOpen onClose={() => setIsOpenSortPatrol(false)}>
                    <div
                      className={Styles.wrapSortPatrol}
                      is_transition={isTransition.toString()}
                    >
                      <div className={Styles.header}>
                        <span>Atur Titik Patroli</span>
                        <Icon
                          icon={"close-circle"}
                          size={"14"}
                          className={Styles.iconClose}
                          onClick={() => {
                            setIsOpenSortPatrol(false);
                            setTransition(false);
                          }}
                        />
                      </div>

                      <div className={Styles.wrapBetween}>
                        <div
                          className={Styles.mainContain}
                          // ref={listInnerRefSortContain}
                          // onScroll={
                          //   isOpenSortPatrol ? onScrollSort : () => void {}
                          // }
                        >
                          {cardOrder.map((el, idx) => (
                            <SortableCard
                              key={`sort-${el._id}`}
                              card={el}
                              index={idx}
                              moveCard={moveCard}
                              isUnsetBorder={data?.data?.length - 1 === idx}
                            />
                          ))}
                          {isLoadingScroll && (
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Spinner />
                            </div>
                          )}
                        </div>
                        <div className={Styles.btnSave}>
                          <Button
                            text="Simpan Perubahan"
                            onClick={() => handleSortPatrolPoint()}
                            isDisabled={isLoadingHit}
                          />
                        </div>
                      </div>
                    </div>
                  </Modal>
                </DndProvider>
              )}
            </div>
          )}
          {cardOrder.length === 0 && debounceQuery ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "60vh",
                alignItems: "center",
              }}
            >
              <CustomEmptyHandlerPage
                title="Data Tidak Ditemukan"
                description={`Tidak ada nama lokasi untuk hasil pencarian ${searchQuery}`}
                images={Images.RAILWAY_STATION}
                isAddButton={false}
              />
            </div>
          ) : (
            <div
              className={Styles.cardLocation}
              onScroll={onScroll}
              ref={listInnerRefMainContain}
              style={{ maxHeight: "100%", overflowY: "auto" }}
            >
              {(isInitialLoader ? Array.from({ length: 10 }) : data?.data)?.map(
                (el, idx) => (
                  <Fragment key={isInitialLoader ? idx : el?._id}>
                    <div className={Styles.card}>
                      <div className={Styles.img}>
                        {isInitialLoader ? (
                          <BoxSkeleton w="260px" h="100%" />
                        ) : (
                          <img
                            alt="image-location"
                            src={fileBaseUrl + el?.imageURI}
                          />
                        )}
                      </div>

                      <div className={Styles.description}>
                        <div className={Styles.personil}>
                          {isInitialLoader ? (
                            <Skeleton w="150px" />
                          ) : (
                            <strong>{capitalizeEachWord(el?.name)}</strong>
                          )}
                          <div>
                            {isInitialLoader ? (
                              <Skeleton w="100px" h="16px" />
                            ) : (
                              <span>{capitalizeEachWord(el?.description)}</span>
                            )}
                          </div>
                        </div>
                        {isInitialLoader ? (
                          <Skeleton w="180px" h="16px" />
                        ) : (
                          <div
                            className={Styles.btnCard}
                            onClick={() => {
                              navigate(el?._id);
                              sessionStorage.setItem(
                                "patrolPointName",
                                capitalizeEachWord(el?.name)
                              );
                            }}
                          >
                            <span>Lihat Detail</span>
                            <Icon
                              icon={"arrow-back"}
                              className={Styles.arrowIcon}
                            />
                          </div>
                        )}
                      </div>

                      {width < 768 ? (
                        <PDFBarcodeDownload
                          checkpoint={el?.name}
                          id={el?._id}
                          description={el?.description}
                          headerSubText="QR Code Titik Patroli"
                        >
                          <div className={Styles.detailQr}>
                            <span>Download QR</span>
                            <Icon
                              icon={"scan-barcode"}
                              color={"#2C5364"}
                              size={"16"}
                            />
                          </div>
                        </PDFBarcodeDownload>
                      ) : (
                        <div
                          className={Styles.detailQr}
                          onClick={
                            isInitialLoader
                              ? () => void {}
                              : () =>
                                  window.open(
                                    `/pdf-preview?dataQuery=barcode&id=${el?._id}&postName=${el?.name}&headerSubText=QR Code Titik Patroli &description=${el?.description}`,
                                    "_blank"
                                  )
                          }
                        >
                          <span>Detail QR</span>
                          <Icon
                            icon={"scan-barcode"}
                            color={"#2C5364"}
                            size={"16"}
                          />
                        </div>
                      )}
                    </div>
                  </Fragment>
                )
              )}
            </div>
          )}
          {isLoadingScroll && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                bottom: "1rem",
              }}
            >
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
