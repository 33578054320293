import React, { useCallback, useEffect, useMemo, useState } from "react";
import Styles from "./style.module.scss";
import Button from "@Atom/Button";
import Table from "@Molecule/Table";
import TabHeader from "@Atom/TabHeader";
import EmptyCoordinator from "@Assets/Images/empty-handler-coordinator.png";
import EmptyTableClient from "@Assets/Images/emptyPIC.png";
import Modal from "@Atom/Modal";
import Icon from "@Atom/Icon";
import InputField from "@Atom/InputField";
import Toast from "@Atom/Toast";
import { createCoordinatorExternal } from "@Services/clients/createCoordinator";
import { fetchAllCoordinatorExternal } from "@Services/clients/fetchAllCoordinatorExternal";
import { capitalizeEachWord } from "helpers/capitalizeEachWord";
import { deleteCoordinatorExternal } from "@Services/clients/deleteExternalCoordinator";
import { updateCoordinatorExternal } from "@Services/clients/updateExternalCoordinator";
import moment from "moment";
import { getClientPIC } from "@Services/clients/getClientPIC";
import AVA_DEFAULT from "@Assets/Images/ava-default.png";

import { getClientContact } from "@Services/clients/getClientContact";
export default function DetailClient({
  datasProfileClient,
  datasPIC,
  datasContact,
  datasExtCoor,
}) {
  //PIC
  const [pagePIC, setPagePIC] = useState(1);
  const [limitPIC, setLimitPIC] = useState(10);
  const [dataRefetchPIC, setDataRefetchPIC] = useState(datasPIC);
  const [isLoadingHitFetchPIC, setIsLoadingHitFetchPIC] = useState(false);

  useEffect(() => {
    sessionStorage.setItem("limitPICRecord", limitPIC);
    sessionStorage.setItem("pagePICRecord", pagePIC);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllClientPIC = useCallback(async () => {
    setIsLoadingHitFetchPIC(true);
    try {
      const { data } = await getClientPIC(pagePIC, limitPIC);
      if (data.status) {
        const res = data?.data;
        setDataRefetchPIC(res);
      }
    } catch (error) {
      console.log("Error get data PIC: ", error);
    } finally {
      setIsLoadingHitFetchPIC(false);
    }
  }, [limitPIC, pagePIC]);

  useEffect(() => {
    if (
      +sessionStorage.getItem("limitPICRecord") !== limitPIC ||
      +sessionStorage.getItem("pagePICRecord") !== pagePIC
    ) {
      sessionStorage.removeItem("limitPICRecord");
      sessionStorage.removeItem("pagePICRecord");
      getAllClientPIC();
    }
  }, [getAllClientPIC, limitPIC, pagePIC]);

  // END PIC

  // CONTACT

  const [pageContactClient, setPageContactClient] = useState(1);
  const [limitContactClient, setLimitContactClient] = useState(10);
  const [dataRefetchContactClient, setDataRefetchContactClient] =
    useState(datasContact);
  const [isLoadingHitFetchContactClient, setIsLoadingHitFetchContactClient] =
    useState(false);

  useEffect(() => {
    sessionStorage.setItem("limitContactClientRecord", limitContactClient);
    sessionStorage.setItem("pageContactClientRecord", pageContactClient);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllContactClient = useCallback(async () => {
    setIsLoadingHitFetchContactClient(true);
    try {
      const { data } = await getClientContact(
        pageContactClient,
        limitContactClient
      );
      if (data.status) {
        const res = data?.data;
        setDataRefetchContactClient(res);
      }
    } catch (error) {
      console.log("Error get data Contact client: ", error);
    } finally {
      setIsLoadingHitFetchContactClient(false);
    }
  }, [limitContactClient, pageContactClient]);

  useEffect(() => {
    if (
      +sessionStorage.getItem("limitContactClientRecord") !==
        limitContactClient ||
      +sessionStorage.getItem("pageContactClientRecord") !== pageContactClient
    ) {
      sessionStorage.removeItem("limitContactClientRecord");
      sessionStorage.removeItem("pageContactClientRecord");
      getAllContactClient();
    }
  }, [getAllContactClient, limitContactClient, pageContactClient]);

  // END CONTACT

  const clientActive = JSON.parse(localStorage?.getItem("currentClient"));
  const [isOpenModalAddCoordinator, setIsOpenModalAddCoordinator] =
    useState(false);
  const [dataCoordinatorExternal, setDataCoordinatorExternal] = useState(
    datasExtCoor || {
      totalPage: "",
      totalData: "",
      data: [],
    }
  );
  const [limitCoordnator, setLimitCoordnator] = useState(10);
  const [pageCoordinator, setPageCoordinator] = useState(1);

  useEffect(() => {
    sessionStorage.setItem("limitCoorExt", limitCoordnator);
    sessionStorage.setItem("pageCoorExt", pageCoordinator);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isLoadingHitFetchCoor, setIsLoadingHitFetchCoor] = useState(false);
  const getAllCoordintorExternal = useCallback(
    async (refetchLoad = true) => {
      setIsLoadingHitFetchCoor(refetchLoad);
      try {
        const res = await fetchAllCoordinatorExternal(
          pageCoordinator,
          limitCoordnator
        );
        if (res.status === 200) {
          setDataCoordinatorExternal(res?.data);
        }
      } catch (error) {
        console.log("Error get all coordinator: ", error);
      } finally {
        setIsLoadingHitFetchCoor(false);
      }
    },
    [limitCoordnator, pageCoordinator]
  );

  useEffect(() => {
    if (
      +sessionStorage.getItem("limitCoorExt") !== limitCoordnator ||
      +sessionStorage.getItem("pageCoorExt") !== pageCoordinator
    ) {
      getAllCoordintorExternal(true);
      sessionStorage.removeItem("limitCoorExt");
      sessionStorage.removeItem("pageCoorExt");
    }
  }, [getAllCoordintorExternal, limitCoordnator, pageCoordinator]);

  // useEffect(() => {
  //   getAllCoordintorExternal(true);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pageCoordinator, limitCoordnator]);

  const [isSuccessDeleteCoorExt, setIsSuccessDeleteCoorExt] = useState(false);
  const [isErrorDeleteCoorExt, setIsErrorDeleteCoorExt] = useState(false);
  const [deleteCoorName, setDeleteCoorName] = useState(null);

  const handleDeleteCoorExt = useCallback(
    async (id) => {
      setIsSuccessDeleteCoorExt(false);
      setIsErrorDeleteCoorExt(false);

      try {
        const res = await deleteCoordinatorExternal(id);
        if (res.status === 200) {
          setIsSuccessDeleteCoorExt(true);
          setPageCoordinator(1);
          getAllCoordintorExternal(true);
          // setDeleteCoorExtID(id);
        }
      } catch (error) {
        setIsErrorDeleteCoorExt(true);
        console.log("Error delete coordinator external: ", error);
      }
    },
    [getAllCoordintorExternal]
  );

  // const nameToDeletedCoorExt = () => {
  //   const coordinator = dataCoordinatorExternal?.data?.find(
  //     (c) => c?._id === deleteCoorExtID
  //   );
  //   return capitalizeEachWord(coordinator?.name) || "";
  // };

  // console.log("APA", nameToDeletedCoorExt());

  const dataTableCoordinator = useMemo(() => {
    return {
      data: dataCoordinatorExternal?.data?.map((c) => ({
        id: c?._id || "-",
        name: capitalizeEachWord(c?.name),
        position: capitalizeEachWord(c?.rank),
        phoneNumber: c?.phoneNumber.startsWith("+")
          ? c?.phoneNumber
          : `+${c?.phoneNumber}`,
      })),
      columns: [
        {
          name: "name",
          title: <TabHeader text="Nama" />,
          width: "40%",
          renderData: (row) => (
            <div>
              <span style={{ color: "#3E4856" }}>{row.name}</span>
            </div>
          ),
        },
        {
          name: "position",
          width: "30%",
          title: <TabHeader text="Jabatan" />,
          renderData: (row) => (
            <span style={{ color: "#3E4856" }}>{row.position}</span>
          ),
        },
        {
          name: "position",
          width: "30%",
          title: <TabHeader text="No Whatsapp" />,
          renderData: (row) => (
            <div style={{ display: "flex", gap: "2px", alignItems: "center" }}>
              <span style={{ color: "#A9B3C1" }}>
                {row.phoneNumber.slice(0, 3)}
              </span>
              <span style={{ color: "#3E4856" }}>
                {row.phoneNumber.slice(3)}
              </span>
            </div>
          ),
        },
        {
          name: "",
          renderData: (row) => (
            <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
              <Icon
                icon={"trash"}
                style={{ cursor: "pointer" }}
                color={"red"}
                size={"16"}
                onClick={() => {
                  handleDeleteCoorExt(row?.id);
                  setDeleteCoorName(row?.name);
                }}
              />
              <Icon
                onClick={() => setIsOpenModalAddCoordinator({ dataEdit: row })}
                icon={"edit-2"}
                style={{ cursor: "pointer" }}
                color={"#3e4856"}
                size={"18"}
              />
            </div>
          ),
        },
      ],
    };
  }, [dataCoordinatorExternal?.data, handleDeleteCoorExt]);

  const [valueAddCoordinator, setValueAddCoordinator] = useState({
    name: "",
    position: "",
    phoneNumber: "",
  });

  const handleChangeAddCoordinator = (value, name) => {
    if (name === "phoneNumber") {
      // if (value.startsWith("+"))
      setValueAddCoordinator((prev) => ({
        ...prev,
        [name]: value?.replace(/[^\d+]/g, ""),
      }));
    } else {
      setValueAddCoordinator((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const [isSuccessCreateCoor, setIsSuccessCreateCoor] = useState(false);
  const [isErrorCreateCoor, setIsErrorCreateCoor] = useState(false);
  const [isLoadingHitCreateCoor, setIsLoadingHitCreateCoor] = useState(false);

  const handleAddCoordinator = async () => {
    setIsLoadingHitCreateCoor(true);
    setIsErrorCreateCoor(false);
    setIsSuccessCreateCoor(false);
    try {
      const { name, phoneNumber, position } = valueAddCoordinator;
      const toSendPhoneNumber = phoneNumber.startsWith("+")
        ? phoneNumber.slice(1)
        : phoneNumber
        ? phoneNumber.slice(0)
        : phoneNumber;

      const dataToSend = new URLSearchParams();
      dataToSend.append("name", name);
      dataToSend.append("phoneNumber", toSendPhoneNumber);
      dataToSend.append("clientCode", clientActive.code);
      if (position) {
        dataToSend.append("rank", position);
      }

      const res = await createCoordinatorExternal(dataToSend);
      if (res.status === 201 || res.status === 200) {
        setIsSuccessCreateCoor(true);
        getAllCoordintorExternal(true);
        setIsOpenModalAddCoordinator(false);
        setValueAddCoordinator({
          phoneNumber: "",
          name: "",
          position: "",
        });
      }
    } catch (error) {
      setIsErrorCreateCoor(true);
      console.log("Error create coordinator: ", error);
    } finally {
      setIsLoadingHitCreateCoor(false);
    }
  };

  const handleClearDataEditCoor = () => {
    if (isOpenModalAddCoordinator?.dataEdit) {
      setValueAddCoordinator((prev) => ({
        ...prev,
        name: "",
        phoneNumber: "",
        position: "",
      }));
    }
  };

  useEffect(() => {
    if (isOpenModalAddCoordinator?.dataEdit) {
      const { dataEdit } = isOpenModalAddCoordinator;
      setValueAddCoordinator((prev) => ({
        ...prev,
        name: dataEdit?.name,
        phoneNumber: dataEdit?.phoneNumber,
        position: dataEdit?.position,
      }));
    }
  }, [clientActive.code, isOpenModalAddCoordinator]);

  const [isSuccessUpdateCoor, setIsSuccessUpdateCoor] = useState(false);
  const [isErrorUpdateCoor, setIsErrorUpdateCoor] = useState(false);

  const handleUpdateCoordinator = async () => {
    setIsLoadingHitCreateCoor(true);
    setIsSuccessUpdateCoor(false);
    setIsErrorUpdateCoor(false);
    try {
      const res = await updateCoordinatorExternal({
        name: valueAddCoordinator?.name,
        phoneNumber: valueAddCoordinator?.phoneNumber,
        id: isOpenModalAddCoordinator?.dataEdit?.id,
        rank: valueAddCoordinator?.position,
      });
      if (res.status === 200) {
        setIsOpenModalAddCoordinator(false);
        setIsSuccessUpdateCoor(true);
        getAllCoordintorExternal(true);
        handleClearDataEditCoor();
        setValueAddCoordinator({
          phoneNumber: "",
          name: "",
          position: "",
        });
      }
    } catch (error) {
      setIsErrorUpdateCoor(true);
      console.log("Error update coordinator: ", error);
    } finally {
      setIsLoadingHitCreateCoor(false);
    }
  };

  const profileListClient = useMemo(() => {
    const cName = datasProfileClient;
    return [
      {
        label: "Group",
        icon: "buildings-2",
        value: cName?.groupName || "-",
      },
      {
        hide: true,
        label: "Start - End Date",
        icon: "calendar-edit",
        value: cName?.startDate
          ? `${moment(cName.startDate).format("LL")} - ${moment(
              cName.endDate
            ).format("LL")} `
          : "-",
      },
      {
        label: "No Telp",
        icon: "call",
        value: cName?.phoneNumber || "-",
      },
      {
        label: "Email",
        icon: "sms",
        value: cName?.email || "-",
      },
      {
        label: "Website",
        icon: "global",
        value: cName?.website || "-",
      },
      {
        label: "Alamat",
        icon: "map",
        value: cName?.address || "-",
      },
    ];
  }, [datasProfileClient]);

  const [isSuccessClipboard, setIsSuccessClipBoard] = useState(false);
  const [isErrorClipboard, setIsErrorClipBoard] = useState(false);

  const handleCopyClipBoard = async (email) => {
    try {
      await navigator.clipboard.writeText(email);
      setIsSuccessClipBoard(true);
    } catch (error) {
      console.log("Failed to copy email", error);
      setIsErrorClipBoard(true);
    }
  };

  const dataTablePIC = useMemo(() => {
    return {
      data:
        dataRefetchPIC?.data?.map((p) => ({
          id: p?.employeeId,
          name: capitalizeEachWord(p?.name),
          profilePic: p?.profilePicture ?? AVA_DEFAULT,
          region: capitalizeEachWord(p?.region),
          phoneNumber: p?.phoneNumber.startsWith("+")
            ? p?.phoneNumber
            : p?.phoneNumber.startsWith("62")
            ? "+" + p?.phoneNumber
            : p?.phoneNumber,
          email: p?.email,
        })) || [],
      columns: [
        {
          name: "name",
          title: <TabHeader text="Nama PIC" />,
          width: "40%",
          renderData: (row) => (
            <div className={Styles.namePIC}>
              <img
                src={row.profilePic}
                style={{ width: "24px", height: "24px" }}
                alt="profil-pic"
              />
              <div>
                <p>{row?.name}</p>
                <span>{row.region}</span>
              </div>
            </div>
          ),
        },
        {
          name: "email",
          width: "40%",
          title: <TabHeader text="Email" />,
          renderData: (row) => (
            <div className={Styles.emailCopy}>
              <Icon
                onClick={() => handleCopyClipBoard(row?.email)}
                icon={"copy"}
                className={Styles.icon}
                size={"16"}
              />
              <span style={{ color: "#3E4856" }}>{row.email}</span>
            </div>
          ),
        },
        {
          name: "phoneNumber",
          width: "30%",
          title: <TabHeader text="No HP" />,
          renderData: (row) => (
            <div style={{ display: "flex", gap: "2px", alignItems: "center" }}>
              <span style={{ color: "#A9B3C1" }}>
                {row.phoneNumber.slice(0, 3)}
              </span>
              <span style={{ color: "#3E4856" }}>
                {row.phoneNumber.slice(3)}
              </span>
            </div>
          ),
        },
      ],
    };
  }, [dataRefetchPIC?.data]);

  const dataTableContact = useMemo(() => {
    return {
      data:
        dataRefetchContactClient?.data?.map((c) => ({
          id: c?.employeeId,
          name: capitalizeEachWord(c?.name),
          profilePic: c?.profilePicture ?? AVA_DEFAULT,
          position: capitalizeEachWord(c?.jobTitle),
          phoneNumber: c?.phoneNumber.startsWith("+")
            ? c?.phoneNumber
            : c?.phoneNumber.startsWith("62")
            ? "+" + c?.phoneNumber
            : c?.phoneNumber,
          email: c?.email,
        })) || [],
      columns: [
        {
          name: "name",
          title: <TabHeader text="Nama" />,
          width: "40%",
          renderData: (row) => (
            <div className={Styles.namePIC}>
              <img
                src={row.profilePic}
                style={{ width: "24px", height: "24px" }}
                alt="profil-pic"
              />
              <div>
                <p>{row?.name}</p>
                <span>{row.position}</span>
              </div>
            </div>
          ),
        },
        {
          name: "email",
          width: "40%",
          title: <TabHeader text="Email" />,
          renderData: (row) => (
            <div className={Styles.emailCopy}>
              <Icon
                onClick={() => handleCopyClipBoard(row?.email)}
                icon={"copy"}
                className={Styles.icon}
                size={"16"}
              />
              <span style={{ color: "#3E4856" }}>{row.email}</span>
            </div>
          ),
        },
        {
          name: "phoneNumber",
          width: "30%",
          title: <TabHeader text="No HP" />,
          renderData: (row) => (
            <div style={{ display: "flex", gap: "2px", alignItems: "center" }}>
              <span style={{ color: "#A9B3C1" }}>
                {row.phoneNumber.slice(0, 3)}
              </span>
              <span style={{ color: "#3E4856" }}>
                {row.phoneNumber.slice(3)}
              </span>
            </div>
          ),
        },
      ],
    };
  }, [dataRefetchContactClient?.data]);

  return (
    <div className={Styles.wrapperLayoutDetailClient}>
      <section className={Styles.profileClient}>
        <h2>Detail Klien</h2>
        <div className={Styles.mainProfile}>
          <div className={Styles.header}>
            {/* <img src="https://placehold.co/48x48/skyblue/white" alt="pic" /> */}
            <div className={Styles.imgAvatar}>
              <h1>
                {datasProfileClient.name.split(" ")[0] === "PT" ||
                datasProfileClient.name.split(" ")[0] === "CV"
                  ? datasProfileClient.name.split(" ")[1].split("")[0]
                  : datasProfileClient.name.split("")[0]}
              </h1>
            </div>
            <h4>{datasProfileClient?.name}</h4>
          </div>
          <div className={Styles.divider}></div>
          <div className={Styles.labelAndValue}>
            <div className={Styles.label}>
              {profileListClient.map(
                (l, idx) =>
                  !l?.hide && (
                    <div key={`label-and-value-${idx}`}>
                      <div>
                        <Icon icon={l.icon} size={"20px"} color={"#718198"} />
                        <span>{l.label}</span>
                      </div>
                      <span className={Styles.value}>{l?.value}</span>
                    </div>
                  )
              )}
            </div>
          </div>
        </div>
      </section>
      <section className={Styles.viewRowTable}>
        <div className={Styles.pic}>
          <h3>Daftar PIC</h3>
          <div className={Styles.card}>
            <div className={Styles.wrapperTableToScroll}>
              <Table
                data={dataTablePIC.data}
                columns={dataTablePIC.columns}
                totalData={dataRefetchPIC.totalData}
                withPagination={dataRefetchPIC?.totalData > 10}
                limit={limitPIC}
                setLimit={setLimitPIC}
                page={pagePIC}
                setPage={setPagePIC}
                totalPage={Math.ceil(dataRefetchPIC?.totalData / limitPIC)}
                className={Styles.tableRadius}
                isLoading={isLoadingHitFetchPIC}
                isCustomEmptyHandler
                customEmptyHandler={() => <CustomEmptyHandlerTableClient />}
              />
            </div>
          </div>

          <Toast
            isopen={isSuccessClipboard || isErrorClipboard}
            color={isSuccessClipboard ? "green" : "red"}
            text={
              isSuccessClipboard
                ? "Email berhasil disalin"
                : "Email gagal disalin "
            }
            timeOut={3000}
            onClose={() => {
              setIsErrorClipBoard(false);
              setIsSuccessClipBoard(false);
            }}
          />
        </div>
        <div className={Styles.contact}>
          <h3>Informasi Kontak Perusahaan</h3>
          <div className={Styles.card}>
            <div className={Styles.wrapperTableToScroll}>
              <Table
                data={dataTableContact.data}
                columns={dataTableContact.columns}
                totalData={dataRefetchContactClient.totalData}
                withPagination={dataRefetchContactClient?.totalData > 10}
                limit={limitContactClient}
                setLimit={setLimitContactClient}
                page={pageContactClient}
                setPage={setPageContactClient}
                totalPage={Math.ceil(
                  dataRefetchContactClient?.totalData / limitContactClient
                )}
                className={Styles.tableRadius}
                isLoading={isLoadingHitFetchContactClient}
                isCustomEmptyHandler
                customEmptyHandler={() => (
                  <CustomEmptyHandlerTableClient
                    textHeader="Data kontak Tidak Tersedia"
                    description="Belum ada data kontak perusahaan yang ditambahkan kedalam daftar"
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className={Styles.coordinator}>
          <div className={Styles.between}>
            <h3>Koordinator Eksternal</h3>
            <Button
              text="Tambah Kordinator Eksternal"
              isAddButton
              style={{
                background: "transparent",
                color: "#2C5364",
                border: "none",
                paddingInline: "0",
              }}
              colorIcon={"#2C5364"}
              onClick={() => setIsOpenModalAddCoordinator(true)}
            />
          </div>
          <div className={`${Styles.card} ${Styles.coordinatorCard}`}>
            <div className={Styles.wrapperTableToScroll}>
              <Table
                data={dataTableCoordinator.data}
                columns={dataTableCoordinator.columns}
                totalData={dataCoordinatorExternal.totalData}
                totalPage={Math.ceil(
                  dataCoordinatorExternal.totalData / limitCoordnator
                )}
                withPagination={dataCoordinatorExternal?.totalData > 10}
                limit={limitCoordnator}
                setLimit={setLimitCoordnator}
                page={pageCoordinator}
                setPage={setPageCoordinator}
                className={Styles.tableRadius}
                isLoading={isLoadingHitFetchCoor}
                customEmptyHandler={() => (
                  <EmptyHandlerCoordinator
                    onClick={() => setIsOpenModalAddCoordinator(true)}
                  />
                )}
                isCustomEmptyHandler
              />
            </div>
            <Toast
              isopen={isSuccessCreateCoor || isErrorCreateCoor}
              color={isSuccessCreateCoor ? "green" : "red"}
              text={
                isSuccessCreateCoor
                  ? "Kordinator eksternal berhasil ditambahkan"
                  : "Kordinator eksternal gagal ditambahkan"
              }
              onClose={() => {
                setIsSuccessCreateCoor(false);
                setIsErrorCreateCoor(false);
              }}
              timeOut={3000}
            />

            <Toast
              isopen={isSuccessUpdateCoor || isErrorUpdateCoor}
              color={isSuccessUpdateCoor ? "green" : "red"}
              text={
                isSuccessUpdateCoor
                  ? "Kordinator eksternal berhasil diperbarui"
                  : "Kordinator eksternal gagal diperbarui"
              }
              onClose={() => {
                setIsSuccessUpdateCoor(false);
                setIsErrorUpdateCoor(false);
              }}
              timeOut={3000}
            />

            <Toast
              isopen={isSuccessDeleteCoorExt || isErrorDeleteCoorExt}
              color={isSuccessDeleteCoorExt ? "green" : "red"}
              text={
                isSuccessDeleteCoorExt
                  ? `${deleteCoorName} dari Kordinator eksternal berhasil hapus`
                  : `${deleteCoorName} dari Kordinator eksternal gagal hapus`
              }
              onClose={() => {
                setIsSuccessDeleteCoorExt(false);
                setIsErrorDeleteCoorExt(false);
              }}
              timeOut={5000}
            />

            <Modal
              isOpen={
                isOpenModalAddCoordinator === true ||
                !!isOpenModalAddCoordinator?.dataEdit
              }
            >
              <div className={Styles.modalAddCoordinator}>
                <div className={Styles.header}>
                  <Icon icon={"card-add"} size={"24"} color={"#2C5364"} />
                  <strong>
                    {isOpenModalAddCoordinator?.dataEdit ? "Edit" : "Tambah"}{" "}
                    Kordinator Eksternal
                  </strong>
                </div>
                <InputField
                  isLabel
                  textLabel="Nama"
                  placeholder="Masukkan nama"
                  setValue={(e) => handleChangeAddCoordinator(e, "name")}
                  value={valueAddCoordinator?.name}
                  isRequired
                />
                <InputField
                  isLabel
                  textLabel="Jabatan"
                  placeholder="Masukkan nama jabatan"
                  setValue={(e) => handleChangeAddCoordinator(e, "position")}
                  value={valueAddCoordinator?.position}
                />
                <InputField
                  isLabel
                  textLabel="Nomor Whatsapp"
                  placeholder="+62"
                  setValue={(e) => handleChangeAddCoordinator(e, "phoneNumber")}
                  value={
                    !valueAddCoordinator?.phoneNumber?.startsWith("+")
                      ? ""
                      : valueAddCoordinator.phoneNumber
                  }
                  isRequired
                />
                <div className={Styles.buttonAction}>
                  <Button
                    text="kembali"
                    style={{
                      background: "transparent",
                      color: "#2C5364",
                      padding: "8px 12px",
                    }}
                    onClick={() => {
                      handleClearDataEditCoor();
                      setIsOpenModalAddCoordinator(false);
                    }}
                  />
                  <Button
                    text="Simpan"
                    isLoadingIndicator={isLoadingHitCreateCoor}
                    onClick={
                      isOpenModalAddCoordinator?.dataEdit
                        ? () => handleUpdateCoordinator()
                        : () => handleAddCoordinator()
                    }
                    isDisabled={
                      !valueAddCoordinator.name ||
                      !valueAddCoordinator.phoneNumber.startsWith("+")
                    }
                  />
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </section>
    </div>
  );
}

const EmptyHandlerCoordinator = ({ onClick = () => {} }) => {
  return (
    <div className={Styles.wrapperEmptycoor}>
      <img src={EmptyCoordinator} alt="empty" />
      <span>Belum ada data yang dapat ditampilkan</span>
      <Button
        text="Kordinator Eksternal"
        isAddButton
        isLeftIcon
        onClick={onClick}
        style={{
          background: "transparent",
          color: "#2C5364",
          padding: "8px 12px",
        }}
        colorIcon={"#2C5364"}
      />
    </div>
  );
};

const CustomEmptyHandlerTableClient = ({
  textHeader = "Data PIC Tidak Tersedia",
  description = "Belum ada data PIC yang ditambahkan kedalam daftar",
}) => {
  return (
    <div className={Styles.wrapEmptyTableClient}>
      <img src={EmptyTableClient} alt="empty" />
      <h2>{textHeader}</h2>
      <span>{description}</span>
    </div>
  );
};
