import React, { useEffect, useState } from "react";
import Styles from "./style.module.scss";
import Modal from "@Atom/Modal";
import Icon from "@Atom/Icon";
import InputField from "@Atom/InputField";
import Button from "@Atom/Button";
import Map from "@Molecule/Map";
import { fileBaseUrl } from "configs";
import Geocode from "react-geocode";

export default function ModalAddLocation({
  isOpen = false,
  fileRef = null,
  onSubmit = () => {},
  onClose = () => {},
  setInputLocation = () => {},
  inputLocation = {
    image: "",
    name: "",
    nameLead: "",
    leadPhoneNumber: "",
    address: "",
    guardRadius: "",
    schemaAbsence: { list: "", id: "" },
    latitude: "",
    longitude: "",
  },
  isFilled = false,
  setSelectedLocation = () => {},
  selectedLocation = {
    latitude: 0,
    longitude: 0,
  },
  isDisabled = false,
  dataListDropdown = [{ name: "Placeholder", id: "123" }],
  setSelectedDropdownID = () => {},
}) {
  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
  Geocode.setLanguage("id");
  Geocode.setRegion("ID");

  useEffect(() => {
    const setDefaultLocation = async () => {
      if (!selectedLocation?.latitude || !selectedLocation?.longitude) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;

            setSelectedLocation({
              latitude,
              longitude,
            });

            Geocode.fromLatLng(latitude, longitude).then(
              (_) => {},
              (error) => {
                console.error("Geocode error", error);
              }
            );
          },
          (error) => {
            console.error("Geolocation error", error);
            setSelectedLocation({
              latitude: -6.2,
              longitude: 106.816666,
            });
          }
        );
      } else {
        Geocode.fromLatLng(
          selectedLocation.latitude,
          selectedLocation.longitude
        ).then(
          (_) => {},
          (error) => {
            console.error("Geocode error", error);
          }
        );
      }
    };

    setDefaultLocation();
  }, [selectedLocation, setSelectedLocation]);

  const handleChangeInput = (name, value) => {
    if (name === "schemaAbsence") {
      setInputLocation((prev) => ({
        ...prev,
        [name]: {
          ...prev[name],
          ...value,
        },
      }));
    } else if (name === "leadPhoneNumber" || name === "guardRadius") {
      const inputNumber = value.replace(/\D/g, "");
      setInputLocation((prev) => ({
        ...prev,
        [name]: inputNumber,
      }));
    } else {
      setInputLocation((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const [isTransition, setTransition] = useState(false);
  if (isFilled && isOpen) {
    setTimeout(() => {
      setTransition(true);
    }, 100);
  }

  const [prevImage, setPrevImage] = useState();
  useEffect(() => {
    setPrevImage(inputLocation.image);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleApplyCoordinate = () => {
    const { latitude, longitude } = inputLocation;
    if (latitude && longitude)
      setSelectedLocation((prev) => ({
        ...prev,
        latitude: +latitude,
        longitude: +longitude,
      }));
  };

  useEffect(() => {
    setInputLocation((c) => ({
      ...c,
      latitude: selectedLocation.latitude,
      longitude: selectedLocation.longitude,
    }));
  }, [selectedLocation.latitude, selectedLocation.longitude, setInputLocation]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div
        className={Styles.modalAddWorkLocation}
        is_filled={(isFilled && isOpen)?.toString()}
        is_transition={isTransition.toString()}
      >
        <div className={Styles.headerModal}>
          <strong>
            {isFilled ? "Pengaturan Lokasi" : "Tambah Lokasi Kerja"}
          </strong>
          <Icon
            icon={"close-circle"}
            onClick={onClose}
            className={Styles.closeCircle}
          />
        </div>
        <div className={Styles.uploadLocation}>
          <strong>
            Foto Lokasi Kerja <span>*</span>
          </strong>
          <div className={Styles.chooseFile}>
            <input
              type="file"
              hidden
              accept=".png, .jpg, .jpeg"
              ref={fileRef}
              onChange={(e) => handleChangeInput("image", e.target.files[0])}
            />
            {inputLocation.image ? (
              <img
                alt="image-location"
                src={
                  typeof inputLocation.image === "object" &&
                  isFilled &&
                  prevImage !== inputLocation.image
                    ? URL.createObjectURL(inputLocation.image)
                    : isFilled && typeof inputLocation.image === "string"
                    ? fileBaseUrl + inputLocation.image
                    : URL.createObjectURL(inputLocation.image)
                }
                onClick={() => fileRef.current.click()}
              />
            ) : (
              <>
                <Icon icon={"document-upload"} color={"#2C5364"} size={"48"} />
                <div className={Styles.viewerCol}>
                  <span>Unggah Foto Lokasi</span>
                  <p>Unggah file dengan format .JPEG, PNG & JPG</p>
                </div>
                <Button
                  text="Browse File"
                  onClick={() => fileRef.current.click()}
                />
              </>
            )}
          </div>
        </div>
        <div className={Styles.inputField}>
          <InputField
            setValue={(v) => handleChangeInput("name", v)}
            textLabel="Nama Lokasi"
            isLabel
            isRequired
            placeholder="Masukkan nama lokasi"
            value={inputLocation.name}
          />
          <div className={Styles.viewColInput}>
            <InputField
              setValue={(v) => handleChangeInput("nameLead", v)}
              textLabel="Penanggung Jawab lokasi"
              isLabel
              isRequired
              placeholder="Masukkan nama penanggung jawab"
              value={inputLocation.nameLead}
            />
            <InputField
              setValue={(v) => handleChangeInput("leadPhoneNumber", v)}
              textLabel="No. Telepon Penanggung Jawab"
              isLabel
              isRequired
              placeholder="Masukkan no telepon penanggung jawab"
              value={inputLocation.leadPhoneNumber}
            />
          </div>
          <InputField
            setValue={(v) => handleChangeInput("address", v)}
            textLabel="Alamat Lokasi"
            isLabel
            isRequired
            placeholder="Masukkan alamat lokasi"
            value={inputLocation.address}
          />
          <InputField
            setValue={(v) => handleChangeInput("guardRadius", v)}
            textLabel="Radius Absensi"
            isLabel
            isRequired
            placeholder="Radius Absensi"
            isAddElement
            textElement="Meter"
            value={inputLocation.guardRadius}
          />
          <InputField
            setValue={(v) => handleChangeInput("schemaAbsence", { list: v })}
            value={inputLocation?.schemaAbsence?.list}
            textLabel="Skema Absensi"
            setSelectedDropdownID={setSelectedDropdownID}
            isLabel
            isRequired
            placeholder="Pilih skema"
            isDropdown
            dataMapDropdown={dataListDropdown}
            isDropdownWithID
          />
          <div className={Styles.coordinatInput}>
            <InputField
              setValue={(e) =>
                handleChangeInput("latitude", e.replace(/[^0-9.-]/g, ""))
              }
              textLabel="Latitude"
              isLabel
              placeholder="Masukkan titik koordinat latitude"
              value={inputLocation.latitude}
              onKeyDown={(e) =>
                e.key === "Enter" && inputLocation.latitude
                  ? handleApplyCoordinate()
                  : {}
              }
            />
            <InputField
              setValue={(e) =>
                handleChangeInput("longitude", e.replace(/[^0-9.]/g, ""))
              }
              textLabel="Longitude"
              isLabel
              placeholder="Masukkan titik koordinat longitude"
              value={inputLocation.longitude}
              onKeyDown={(e) =>
                e.key === "Enter" && inputLocation.longitude
                  ? handleApplyCoordinate()
                  : {}
              }
            />
            <div className={Styles.btnCoord}>
              <Button
                text="Submit"
                isDisabled={!inputLocation.latitude || !inputLocation.longitude}
                onClick={() => handleApplyCoordinate()}
              />
            </div>
          </div>
          <div className={Styles.containerMap}>
            <Map
              isAutoComplete
              selectedLocation={selectedLocation}
              setSelectedLocation={setSelectedLocation}
              coordinate={{
                latitude: selectedLocation.latitude,
                longitude: selectedLocation.longitude,
              }}
              isRadius
              radius={+inputLocation.guardRadius}
              zoom={17}
            />
          </div>
        </div>
        <div className={Styles.footerModal}>
          <Button
            text="Simpan Lokasi Kerja"
            isDisabled={isDisabled}
            onClick={onSubmit}
          />
        </div>
      </div>
    </Modal>
  );
}
